import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { buffer: { type: Number, default: 10 } }

  scroll(event) {
    event.preventDefault()
    const target = event.currentTarget
    const targetId = target.hash
    const targetElement = document.querySelector(targetId)
    const rectTop = targetElement.getBoundingClientRect().top
    const offsetTop = window.scrollY
    const header = document.querySelector('.l-header--sp')
    const headerHeight = header ? header.offsetHeight : 0
    const top = rectTop + offsetTop - headerHeight - this.bufferValue
    window.scrollTo({
      top,
      behavior: 'smooth',
    })
  }
}
