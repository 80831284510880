import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['header', 'mainVisual']

  connect() {
    if (this.hasHeaderTarget && this.hasMainVisualTarget) {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.headerTarget.classList.remove('is-scrolled')
          } else {
            this.headerTarget.classList.add('is-scrolled')
          }
        })
      })

      this.observer.observe(this.mainVisualTarget)
    }
  }
  
  disconnect() {
    if (this.observer) {
      this.observer.disconnect()
      this.observer = null
    }
  }
}
