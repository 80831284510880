import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  static targets = ['mainSlider', 'subSlider', 'switch']

  connect() {
    this.sliderId = this.element.dataset.sliderId

    this.subSlider = new Swiper(this.subSliderTargets.find(target => target.dataset.sliderId === this.sliderId), {
      slidesPerView: 'auto',
      spaceBetween: 8,
      allowTouchMove: false,
      direction: 'vertical',
    })

    this.mainSlider = new Swiper(this.mainSliderTargets.find(target => target.dataset.sliderId === this.sliderId), {
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      allowTouchMove: false,
      thumbs: {
        swiper: this.subSlider
      }
    })

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const ratio = entry.intersectionRatio
          const index = ratio > 0.9 ? 2 : (ratio > 0.6 ? 1 : 0)
          this.mainSlider.slideTo(index)
        }
      })
    }, {
      rootMargin: '200% 0px 0px 0px',
      threshold: [0, 0.55, 0.6, 0.65, 0.85, 0.9, 0.95, 1],
    })

    this.observer.observe(this.switchTargets.find(target => target.dataset.sliderId === this.sliderId))
  }

  disconnect() {
    this.observer.disconnect()
    this.mainSlider.destroy()
    this.subSlider.destroy()
    this.observer == null
    this.mainSlider == null
    this.subSlider == null
  }

  scrollTo(event) {
    const index = event.currentTarget.dataset.slideIndex
    const sw = this.switchTargets.find(sw => sw.dataset.sliderId === this.sliderId)
    const indexOffset = sw.getBoundingClientRect().height * (index === "2" ? 0.45 : (index === "1" ? 0.3 : 0))
    const top = sw.getBoundingClientRect().top + window.scrollY + indexOffset
    window.scrollTo({ top })
  }
}
